import {
  CHANGE_POSTER_FIELD_VALUE,
  CLEAR_POSTERS_ERROR,
  CLEAR_POSTERS_STATE,
  CLEAR_POSTERS_SUCCESS,
  CREATE_POSTER_FAILED,
  CREATE_POSTER_PENDING,
  CREATE_POSTER_SUCCESS,
  EDIT_POSTER_FAILED,
  EDIT_POSTER_PENDING,
  EDIT_POSTER_SUCCESS,
  GET_POSTER_BY_ID_FAILED,
  GET_POSTER_BY_ID_PENDING,
  GET_POSTER_BY_ID_SUCCESS,
  GET_POSTERS_FAILED,
  GET_POSTERS_PENDING,
  GET_POSTERS_SUCCESS
} from '../../constants/posters'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  currentItem: {
    photoId: null,
    cityId: null,
    link: '',
    text: '',
    sequence: '',
    dateStart: new Date(),
    dateEnd: new Date()
  },
  error: null,
  loading: 'idle',
  success: false
}

const postersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_POSTERS_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_POSTERS_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize
      }

    case GET_POSTERS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CREATE_POSTER_PENDING:
      return { ...state, loading: 'pending', error: null }

    case CREATE_POSTER_SUCCESS:
      return { ...state, loading: 'idle', success: true }

    case CREATE_POSTER_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CHANGE_POSTER_FIELD_VALUE:
      const { field, value } = action.payload

      const newState = { ...state, currentItem: { ...state.currentItem, [field]: value } }

      if (field !== 'dateStart' && field !== 'dateEnd') {
        return newState
      }

      const dateStart = newState.currentItem.dateStart
      const dateEnd = newState.currentItem.dateEnd

      if (dateStart > dateEnd) {
        ;[newState.currentItem.dateStart, newState.currentItem.dateEnd] = [dateEnd, dateStart]
      }

      return newState

    case GET_POSTER_BY_ID_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case GET_POSTER_BY_ID_SUCCESS:
      const poster = action.payload

      return {
        ...state,
        currentItem: {
          ...poster,
          dateStart: new Date(poster.dateStart),
          dateEnd: new Date(poster.dateEnd),
          text: poster.text ? poster.text : '',
          link: poster.link ? poster.link : ''
        }
      }

    case GET_POSTER_BY_ID_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_POSTER_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_POSTER_SUCCESS:
      return { ...state, success: true }

    case EDIT_POSTER_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CLEAR_POSTERS_STATE:
      return initialState

    case CLEAR_POSTERS_ERROR:
      return { ...state, error: null }

    case CLEAR_POSTERS_SUCCESS:
      return { ...state, success: false }

    default:
      return state
  }
}

export default postersReducer
