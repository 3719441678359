export const GET_BONUS_ORDERS_PENDING = 'GET_BONUS_ORDERS_PENDING'
export const GET_BONUS_ORDERS_SUCCESS = 'GET_BONUS_ORDERS_SUCCESS'
export const GET_BONUS_ORDERS_FAILED = 'GET_BONUS_ORDERS_FAILED'

export const CHANGE_BONUS_ORDER_STATUS_PENDING = 'CHANGE_BONUS_ORDER_STATUS_PENDING'
export const CHANGE_BONUS_ORDER_STATUS_SUCCESS = 'CHANGE_BONUS_ORDER_STATUS_SUCCESS'
export const CHANGE_BONUS_ORDER_STATUS_FAILED = 'CHANGE_BONUS_ORDER_STATUS_FAILED'

export const GET_BONUS_ORDER_BY_ID_PENDING = 'GET_BONUS_ORDER_BY_ID_PENDING'
export const GET_BONUS_ORDER_BY_ID_SUCCESS = 'GET_BONUS_ORDER_BY_ID_SUCCESS'
export const GET_BONUS_ORDER_BY_ID_FAILED = 'GET_BONUS_ORDER_BY_ID_FAILED'
