export const GET_POSTERS_PENDING = 'GET_POSTERS_PENDING'
export const GET_POSTERS_SUCCESS = 'GET_POSTERS_SUCCESS'
export const GET_POSTERS_FAILED = 'GET_POSTERS_FAILED'

export const GET_POSTER_BY_ID_PENDING = 'GET_POSTER_BY_ID_PENDING'
export const GET_POSTER_BY_ID_SUCCESS = 'GET_POSTER_BY_ID_SUCCESS'
export const GET_POSTER_BY_ID_FAILED = 'GET_POSTER_BY_ID_FAILED'

export const CREATE_POSTER_PENDING = 'CREATE_POSTER_PENDING'
export const CREATE_POSTER_SUCCESS = 'CREATE_POSTER_SUCCESS'
export const CREATE_POSTER_FAILED = 'CREATE_POSTER_FAILED'

export const EDIT_POSTER_PENDING = 'EDIT_POSTER_PENDING'
export const EDIT_POSTER_SUCCESS = 'EDIT_POSTER_SUCCESS'
export const EDIT_POSTER_FAILED = 'EDIT_POSTER_FAILED'

export const CHANGE_POSTER_FIELD_VALUE = 'CHANGE_POSTER_FIELD_VALUE'

export const CLEAR_POSTERS_STATE = 'CLEAR_POSTERS_STATE'
export const CLEAR_POSTERS_ERROR = 'CLEAR_POSTERS_ERROR'
export const CLEAR_POSTERS_SUCCESS = 'CLEAR_POSTERS_SUCCESS'
