export const GET_SETTINGS_PENDING = 'GET_SETTINGS_PENDING'
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_FAILED = 'GET_SETTINGS_FAILED'

export const GET_SETTING_BY_KEY_PENDING = 'GET_SETTING_BY_KEY_PENDING'
export const GET_SETTING_BY_KEY_SUCCESS = 'GET_SETTING_BY_KEY_SUCCESS'
export const GET_SETTING_BY_KEY_FAILED = 'GET_SETTING_BY_KEY_FAILED'

export const CREATE_SETTING_PENDING = 'CREATE_SETTING_PENDING'
export const CREATE_SETTING_SUCCESS = 'CREATE_SETTING_SUCCESS'
export const CREATE_SETTING_FAILED = 'CREATE_SETTING_FAILED'

export const EDIT_SETTING_PENDING = 'EDIT_SETTING_PENDING'
export const EDIT_SETTING_SUCCESS = 'EDIT_SETTING_SUCCESS'
export const EDIT_SETTING_FAILED = 'EDIT_SETTING_FAILED'

export const DELETE_SETTING_PENDING = 'DELETE_SETTING_PENDING'
export const DELETE_SETTING_SUCCESS = 'DELETE_SETTING_SUCCESS'
export const DELETE_SETTING_FAILED = 'DELETE_SETTING_FAILED'

export const CHANGE_SETTING_FIELD_VALUE = 'CHANGE_SETTING_FIELD_VALUE'

export const CLEAR_SETTINGS_STATE = 'CLEAR_SETTINGS_STATE'
export const CLEAR_SETTINGS_ERROR = 'CLEAR_SETTINGS_ERROR'
export const CLEAR_SETTINGS_SUCCESS = 'CLEAR_SETTINGS_SUCCESS'
