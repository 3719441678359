export const GET_BONUSES_PENDING = 'GET_BONUSES_PENDING'
export const GET_BONUSES_SUCCESS = 'GET_BONUSES_SUCCESS'
export const GET_BONUSES_FAILED = 'GET_BONUSES_FAILED'

export const GET_BONUS_BY_ID_PENDING = 'GET_BONUS_BY_ID_PENDING'
export const GET_BONUS_BY_ID_SUCCESS = 'GET_BONUS_BY_ID_SUCCESS'
export const GET_BONUS_BY_ID_FAILED = 'GET_BONUS_BY_ID_FAILED'

export const CREATE_BONUS_PENDING = 'CREATE_BONUS_PENDING'
export const CREATE_BONUS_SUCCESS = 'CREATE_BONUS_SUCCESS'
export const CREATE_BONUS_FAILED = 'CREATE_BONUS_FAILED'

export const EDIT_BONUS_PENDING = 'EDIT_BONUS_PENDING'
export const EDIT_BONUS_SUCCESS = 'EDIT_BONUS_SUCCESS'
export const EDIT_BONUS_FAILED = 'EDIT_BONUS_FAILED'

export const DELETE_BONUS_PENDING = 'DELETE_BONUS_PENDING'
export const DELETE_BONUS_SUCCESS = 'DELETE_BONUS_SUCCESS'
export const DELETE_BONUS_FAILED = 'DELETE_BONUS_FAILED'

export const CHANGE_BONUS_FIELD_VALUE = 'CHANGE_BONUS_FIELD_VALUE'

export const CLEAR_BONUSES_STATE = 'CLEAR_BONUSES_STATE'
export const CLEAR_BONUSES_ERROR = 'CLEAR_BONUSES_ERROR'
export const CLEAR_BONUSES_SUCCESS = 'CLEAR_BONUSES_SUCCESS'
