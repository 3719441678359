import {
  CHANGE_RESTAURANT_FIELD_VALUE,
  CLEAR_RESTAURANTS_ERROR,
  CLEAR_RESTAURANTS_STATE,
  CLEAR_RESTAURANTS_SUCCESS,
  COPY_RESTAURANT_DISHES_FAILED,
  COPY_RESTAURANT_DISHES_PENDING,
  COPY_RESTAURANT_DISHES_SUCCESS,
  CREATE_RESTAURANT_FAILED,
  CREATE_RESTAURANT_PENDING,
  CREATE_RESTAURANT_SUCCESS,
  EDIT_RESTAURANT_FAILED,
  EDIT_RESTAURANT_PENDING,
  EDIT_RESTAURANT_SUCCESS,
  GET_RESTAURANT_BY_ID_FAILED,
  GET_RESTAURANT_BY_ID_PENDING,
  GET_RESTAURANT_BY_ID_SUCCESS,
  GET_RESTAURANTS_FAILED,
  GET_RESTAURANTS_PENDING,
  GET_RESTAURANTS_SUCCESS
} from '../../constants/restaurants'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  currentItem: {
    name: '',
    searchNames: [],
    balance: '',
    login: '',
    password: '',
    logoId: null,
    balanceId: null,
    color: '#000000',
    workTime: '',
    deliveryPrice: '0',
    deliveryTime: '0',
    freeDeliveryPrice: '0',
    payTypes: 'cash',
    sequence: '0',
    isSelfDelivery: false,
    comission: '',
    deliveryCommission: '',
    cities: [],
    isActive: true,
    legalInfo: '',
    fullAdress: '',
    link: '',
    deliveryPriceConditions: [],
    isCashback: false
  },
  error: null,
  loading: 'idle',
  success: false,
  copyDishesError: null,
  copyDishesLoading: 'idle',
  copyDishesSuccess: false
}

const restaurantsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_RESTAURANTS_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_RESTAURANTS_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize
      }

    case GET_RESTAURANTS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CREATE_RESTAURANT_PENDING:
      return { ...state, loading: 'pending', error: null }

    case CREATE_RESTAURANT_SUCCESS:
      return { ...state, loading: 'idle', success: true }

    case CREATE_RESTAURANT_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CHANGE_RESTAURANT_FIELD_VALUE:
      const { field, value } = action.payload

      return { ...state, currentItem: { ...state.currentItem, [field]: value } }

    case GET_RESTAURANT_BY_ID_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case GET_RESTAURANT_BY_ID_SUCCESS:
      const restaurant = action.payload

      return {
        ...state,
        currentItem: {
          ...restaurant,
          deliveryPriceConditions: (restaurant.deliveryPriceConditions || []).map(condition => ({
            id: condition.id,
            fromSum: condition.fromSum.toString(),
            toDistanceInKm: (condition.toDistanceInMeters / 1000).toString(),
            price: condition.price.toString()
          })),
          balance: restaurant.balance.balance,
          login: restaurant.owner.username,
          password: ''
        }
      }

    case GET_RESTAURANT_BY_ID_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_RESTAURANT_PENDING:
      return { ...state, loading: 'pending', error: null }

    case EDIT_RESTAURANT_SUCCESS:
      return { ...state, success: true }

    case EDIT_RESTAURANT_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case COPY_RESTAURANT_DISHES_PENDING:
      return { ...state, copyDishesLoading: 'pending', copyDishesError: null, copyDishesSuccess: false }

    case COPY_RESTAURANT_DISHES_SUCCESS:
      return { ...state, copyDishesLoading: 'idle', copyDishesSuccess: true }

    case COPY_RESTAURANT_DISHES_FAILED:
      return { ...state, copyDishesLoading: 'idle', copyDishesError: action.payload }

    case CLEAR_RESTAURANTS_STATE:
      return initialState

    case CLEAR_RESTAURANTS_ERROR:
      return { ...state, error: null }

    case CLEAR_RESTAURANTS_SUCCESS:
      return { ...state, success: false }

    default:
      return state
  }
}

export default restaurantsReducer
