import {
  CHANGE_USER_FIELD_VALUE,
  CLEAR_CREATE_USER_STATE,
  CLEAR_DELETE_USER_STATE,
  CLEAR_EDIT_USER_STATE,
  CLEAR_USER_STATE,
  CREATE_USER_FAILED,
  CREATE_USER_PENDING,
  CREATE_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_USER_PENDING,
  DELETE_USER_SUCCESS,
  EDIT_USER_FAILED,
  EDIT_USER_PENDING,
  EDIT_USER_SUCCESS,
  GET_USERS_FAILED,
  GET_USERS_PENDING,
  GET_USERS_SUCCESS,
  GET_USER_BY_ID_FAILED,
  GET_USER_BY_ID_PENDING,
  GET_USER_BY_ID_SUCCESS
} from '../../constants/users'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  currentItem: {
    username: '',
    name: '',
    password: '',
    role: 'admin',
    cityId: null
  },

  loading: 'idle',
  error: null,

  createError: null,
  createLoading: 'idle',
  createSuccess: false,

  editeError: null,
  editeLoading: 'idle',
  editeSuccess: false,

  deleteError: null,
  deleteLoading: 'idle',
  deleteSuccess: false
}

const bonusesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_USERS_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_USERS_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize
      }

    case GET_USERS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CREATE_USER_PENDING:
      return { ...state, createLoading: 'pending', createError: null }

    case CREATE_USER_SUCCESS:
      return { ...state, createLoading: 'idle', createSuccess: true }

    case CREATE_USER_FAILED:
      return { ...state, createLoading: 'idle', createError: action.payload }

    case CLEAR_CREATE_USER_STATE:
      return { ...state, createLoading: 'idle', createSuccess: false, createError: null }

    case CHANGE_USER_FIELD_VALUE:
      const { field, value } = action.payload

      return { ...state, currentItem: { ...state.currentItem, [field]: value } }

    case GET_USER_BY_ID_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case GET_USER_BY_ID_SUCCESS:
      const user = action.payload

      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          name: user.name || ''
        }
      }

    case GET_USER_BY_ID_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_USER_PENDING:
      return { ...state, editeLoading: 'idle', editError: action.payload }

    case EDIT_USER_SUCCESS:
      return { ...state, editSuccess: true }

    case EDIT_USER_FAILED:
      return { ...state, editLoading: 'idle', editError: action.payload }

    case CLEAR_EDIT_USER_STATE:
      return { ...state, editLoading: 'idle', editSuccess: false, editError: null }

    case DELETE_USER_PENDING:
      return { ...state, deleteLoading: 'idle', deleteError: action.payload }

    case DELETE_USER_SUCCESS:
      return { ...state, deleteSuccess: true }

    case DELETE_USER_FAILED:
      return { ...state, deleteLoading: 'idle', deleteError: action.payload }

    case CLEAR_DELETE_USER_STATE:
      return { ...state, deleteLoading: 'idle', deleteSuccess: false, deleteError: null }

    case CLEAR_USER_STATE:
      return { ...state, currentItem: initialState.currentItem }

    default:
      return state
  }
}

export default bonusesReducer
