import {
  GET_BONUS_ORDERS_PENDING,
  GET_BONUS_ORDERS_SUCCESS,
  GET_BONUS_ORDERS_FAILED,
  CHANGE_BONUS_ORDER_STATUS_PENDING,
  CHANGE_BONUS_ORDER_STATUS_SUCCESS,
  CHANGE_BONUS_ORDER_STATUS_FAILED,
  GET_BONUS_ORDER_BY_ID_PENDING,
  GET_BONUS_ORDER_BY_ID_SUCCESS,
  GET_BONUS_ORDER_BY_ID_FAILED
} from '../../constants/bonus-orders'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  currentItem: {
    id: '',
    sum: '',
    phone: '',
    status: 'wait',
    bonusId: null,
    clientId: null,
    createdAt: new Date()
  },
  error: null,
  loading: 'idle'
}

const bonusOrdersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_BONUS_ORDERS_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_BONUS_ORDERS_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize
      }

    case GET_BONUS_ORDERS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CHANGE_BONUS_ORDER_STATUS_PENDING:
      return {
        ...state,
        loading: 'idle',
        error: null,
        currentItem: {
          ...state.currentItem,
          status: action.payload
        }
      }

    case CHANGE_BONUS_ORDER_STATUS_SUCCESS:
      return { ...state, success: true }

    case CHANGE_BONUS_ORDER_STATUS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case GET_BONUS_ORDER_BY_ID_PENDING:
      return { ...state, loading: 'idle', error: null }

    case GET_BONUS_ORDER_BY_ID_SUCCESS:
      const bonusOrder = action.payload

      return {
        ...state,
        currentItem: {
          ...bonusOrder,
          createdAt: new Date(bonusOrder.createdAt)
        }
      }

    case GET_BONUS_ORDER_BY_ID_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    default:
      return state
  }
}

export default bonusOrdersReducer
