// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import restaurants from './restaurants'
import posters from './posters'
import stocks from './stocks'
import writeOffs from './write-offs'
import orders from './orders'
import bonuses from './bonuses'
import bonusOrders from './bonus-orders'
import bonusPays from './bonus-pays'
import bonusCompensations from './bonus-compensations'
import restaurantPositions from './restaurant-positions'
import settings from './settings'
import users from './users'
import couriers from './couriers'
import courierPrices from './courier-prices'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  restaurants,
  posters,
  stocks,
  writeOffs,
  orders,
  bonuses,
  bonusOrders,
  bonusPays,
  bonusCompensations,
  restaurantPositions,
  settings,
  users,
  couriers,
  courierPrices
})

export default rootReducer
