import {
  CHANGE_COURIER_PRICE_FIELD_VALUE,
  CLEAR_COURIER_PRICE_STATE,
  CLEAR_CREATE_COURIER_PRICE_STATE,
  CLEAR_EDIT_COURIER_PRICE_STATE,
  CREATE_COURIER_PRICE_FAILED,
  CREATE_COURIER_PRICE_PENDING,
  CREATE_COURIER_PRICE_SUCCESS,
  EDIT_COURIER_PRICE_FAILED,
  EDIT_COURIER_PRICE_PENDING,
  EDIT_COURIER_PRICE_SUCCESS,
  GET_COURIER_PRICE_BY_ID_FAILED,
  GET_COURIER_PRICE_BY_ID_PENDING,
  GET_COURIER_PRICE_BY_ID_SUCCESS,
  GET_COURIER_PRICES_FAILED,
  GET_COURIER_PRICES_PENDING,
  GET_COURIER_PRICES_SUCCESS
} from '../../constants/courier-prices'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  currentItem: {
    fromSum: 0,
    toDistanceInMeters: 0,
    price: 0,
    commission: 0,
    deliveryTime: 0,
    deliveryPriceBase: 0,
    deliveryPricePerKm: 0,
    cityId: null
  },

  loading: 'idle',
  error: null,

  createError: null,
  createLoading: 'idle',
  createSuccess: false,

  editeError: null,
  editeLoading: 'idle',
  editeSuccess: false,

  deleteError: null,
  deleteLoading: 'idle',
  deleteSuccess: false
}

const courierPricesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_COURIER_PRICES_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_COURIER_PRICES_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize
      }

    case GET_COURIER_PRICES_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CREATE_COURIER_PRICE_PENDING:
      return { ...state, createLoading: 'pending', createError: null }

    case CREATE_COURIER_PRICE_SUCCESS:
      return { ...state, createLoading: 'idle', createSuccess: true }

    case CREATE_COURIER_PRICE_FAILED:
      return { ...state, createLoading: 'idle', createError: action.payload }

    case CLEAR_CREATE_COURIER_PRICE_STATE:
      return { ...state, createLoading: 'idle', createSuccess: false, createError: null }

    case CHANGE_COURIER_PRICE_FIELD_VALUE:
      const { field, value } = action.payload

      return { ...state, currentItem: { ...state.currentItem, [field]: value } }

    case GET_COURIER_PRICE_BY_ID_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case GET_COURIER_PRICE_BY_ID_SUCCESS:
      const courierPrice = action.payload

      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          ...courierPrice
        }
      }

    case GET_COURIER_PRICE_BY_ID_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_COURIER_PRICE_PENDING:
      return { ...state, editeLoading: 'idle', editError: action.payload }

    case EDIT_COURIER_PRICE_SUCCESS:
      return { ...state, editSuccess: true }

    case EDIT_COURIER_PRICE_FAILED:
      return { ...state, editLoading: 'idle', editError: action.payload }

    case CLEAR_EDIT_COURIER_PRICE_STATE:
      return { ...state, editLoading: 'idle', editSuccess: false, editError: null }

    case CLEAR_COURIER_PRICE_STATE:
      return { ...state, currentItem: initialState.currentItem }

    default:
      return state
  }
}

export default courierPricesReducer
