export const GET_STOCKS_PENDING = 'GET_STOCKS_PENDING'
export const GET_STOCKS_SUCCESS = 'GET_STOCKS_SUCCESS'
export const GET_STOCKS_FAILED = 'GET_STOCKS_FAILED'

export const GET_STOCK_BY_ID_PENDING = 'GET_STOCK_BY_ID_PENDING'
export const GET_STOCK_BY_ID_SUCCESS = 'GET_STOCK_BY_ID_SUCCESS'
export const GET_STOCK_BY_ID_FAILED = 'GET_STOCK_BY_ID_FAILED'

export const CREATE_STOCK_PENDING = 'CREATE_STOCK_PENDING'
export const CREATE_STOCK_SUCCESS = 'CREATE_STOCK_SUCCESS'
export const CREATE_STOCK_FAILED = 'CREATE_STOCK_FAILED'

export const EDIT_STOCK_PENDING = 'EDIT_STOCK_PENDING'
export const EDIT_STOCK_SUCCESS = 'EDIT_STOCK_SUCCESS'
export const EDIT_STOCK_FAILED = 'EDIT_STOCK_FAILED'

export const CHANGE_STOCK_FIELD_VALUE = 'CHANGE_STOCK_FIELD_VALUE'

export const CLEAR_STOCKS_STATE = 'CLEAR_STOCKS_STATE'
export const CLEAR_STOCKS_ERROR = 'CLEAR_STOCKS_ERROR'
export const CLEAR_STOCKS_SUCCESS = 'CLEAR_STOCKS_SUCCESS'
