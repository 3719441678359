export const GET_COURIERS_PENDING = 'GET_COURIERS_PENDING'
export const GET_COURIERS_SUCCESS = 'GET_COURIERS_SUCCESS'
export const GET_COURIERS_FAILED = 'GET_COURIERS_FAILED'

export const GET_COURIER_BY_ID_PENDING = 'GET_COURIER_BY_ID_PENDING'
export const GET_COURIER_BY_ID_SUCCESS = 'GET_COURIER_BY_ID_SUCCESS'
export const GET_COURIER_BY_ID_FAILED = 'GET_COURIER_BY_ID_FAILED'

export const CREATE_COURIER_PENDING = 'CREATE_COURIER_PENDING'
export const CREATE_COURIER_SUCCESS = 'CREATE_COURIER_SUCCESS'
export const CREATE_COURIER_FAILED = 'CREATE_COURIER_FAILED'
export const CLEAR_CREATE_COURIER_STATE = 'CLEAR_CREATE_COURIER_STATE'

export const EDIT_COURIER_PENDING = 'EDIT_COURIER_PENDING'
export const EDIT_COURIER_SUCCESS = 'EDIT_COURIER_SUCCESS'
export const EDIT_COURIER_FAILED = 'EDIT_COURIER_FAILED'
export const CLEAR_EDIT_COURIER_STATE = 'CLEAR_EDIT_COURIER_STATE'

export const DELETE_COURIER_PENDING = 'DELETE_COURIER_PENDING'
export const DELETE_COURIER_SUCCESS = 'DELETE_COURIER_SUCCESS'
export const DELETE_COURIER_FAILED = 'DELETE_COURIER_FAILED'
export const CLEAR_DELETE_COURIER_STATE = 'CLEAR_DELETE_COURIER_STATE'

export const CHANGE_COURIER_FIELD_VALUE = 'CHANGE_COURIER_FIELD_VALUE'

export const CLEAR_COURIER_STATE = 'CLEAR_COURIER_STATE'
