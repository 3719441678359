import {
  GET_RESTAURANT_POSITIONS_SUCCESS,
  GET_RESTAURANT_POSITIONS_PENDING,
  GET_RESTAURANT_POSITIONS_FAILED,
  CREATE_RESTAURANT_POSITION_PENDING,
  CREATE_RESTAURANT_POSITION_SUCCESS,
  CREATE_RESTAURANT_POSITION_FAILED,
  CHANGE_RESTAURANT_POSITION_FIELD_VALUE,
  CLEAR_RESTAURANT_POSITIONS_STATE,
  CLEAR_RESTAURANT_POSITIONS_ERROR,
  CLEAR_RESTAURANT_POSITIONS_SUCCESS,
  UPDATE_RESTAURANT_POSITION_PENDING,
  UPDATE_RESTAURANT_POSITION_SUCCESS,
  UPDATE_RESTAURANT_POSITION_FAILED,
  DELETE_RESTAURANT_POSITION_PENDING,
  DELETE_RESTAURANT_POSITION_SUCCESS,
  DELETE_RESTAURANT_POSITION_FAILED,
  GET_RESTAURANT_POSITION_BY_ID_PENDING,
  GET_RESTAURANT_POSITION_BY_ID_SUCCESS,
  GET_RESTAURANT_POSITION_BY_ID_FAILED
} from '../../constants/restaurant-positions'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  currentItem: {
    position: 1,
    restaurantId: null,
    cityId: null,
    categoryId: null
  },
  loading: 'idle',
  error: null,
  success: false
}

const restaurantCompensationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_RESTAURANT_POSITIONS_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_RESTAURANT_POSITIONS_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize
      }

    case GET_RESTAURANT_POSITIONS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case GET_RESTAURANT_POSITION_BY_ID_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_RESTAURANT_POSITION_BY_ID_SUCCESS:
      return { ...state, loading: 'idle', currentItem: action.payload }

    case GET_RESTAURANT_POSITION_BY_ID_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CREATE_RESTAURANT_POSITION_PENDING:
      return { ...state, loading: 'pending', error: null }

    case CREATE_RESTAURANT_POSITION_SUCCESS:
      return { ...state, loading: 'idle', success: true }

    case CREATE_RESTAURANT_POSITION_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case UPDATE_RESTAURANT_POSITION_PENDING:
      return { ...state, loading: 'pending', error: null }

    case UPDATE_RESTAURANT_POSITION_SUCCESS:
      return { ...state, loading: 'idle', success: true }

    case UPDATE_RESTAURANT_POSITION_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case DELETE_RESTAURANT_POSITION_PENDING:
      return { ...state, loading: 'pending', error: null }

    case DELETE_RESTAURANT_POSITION_SUCCESS:
      return { ...state, loading: 'idle', success: true }

    case DELETE_RESTAURANT_POSITION_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CHANGE_RESTAURANT_POSITION_FIELD_VALUE:
      const { field, value } = action.payload

      const currentItem = { ...state.currentItem, [field]: value }

      if (field === 'position') {
        currentItem.position = +currentItem.position
      }

      return { ...state, currentItem }

    case CLEAR_RESTAURANT_POSITIONS_STATE:
      return initialState

    case CLEAR_RESTAURANT_POSITIONS_ERROR:
      return { ...state, error: null }

    case CLEAR_RESTAURANT_POSITIONS_SUCCESS:
      return { ...state, success: false }

    default:
      return state
  }
}

export default restaurantCompensationsReducer
