import {
  GET_SETTINGS_PENDING,
  GET_SETTINGS_FAILED,
  GET_SETTINGS_SUCCESS,
  CREATE_SETTING_PENDING,
  CREATE_SETTING_SUCCESS,
  CREATE_SETTING_FAILED,
  CHANGE_SETTING_FIELD_VALUE,
  GET_SETTING_BY_KEY_PENDING,
  GET_SETTING_BY_KEY_SUCCESS,
  GET_SETTING_BY_KEY_FAILED,
  EDIT_SETTING_PENDING,
  EDIT_SETTING_SUCCESS,
  EDIT_SETTING_FAILED,
  CLEAR_SETTINGS_STATE,
  CLEAR_SETTINGS_ERROR,
  CLEAR_SETTINGS_SUCCESS,
  DELETE_SETTING_PENDING,
  DELETE_SETTING_SUCCESS,
  DELETE_SETTING_FAILED
} from '../../constants/settings'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  currentItem: {
    key: 'restaurantsLinkImage',
    value: null
  },
  error: null,
  loading: 'idle',
  success: false
}

const settingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SETTINGS_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_SETTINGS_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize
      }

    case GET_SETTINGS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CREATE_SETTING_PENDING:
      return { ...state, loading: 'pending', error: null }

    case CREATE_SETTING_SUCCESS:
      return { ...state, loading: 'idle', success: true }

    case CREATE_SETTING_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CHANGE_SETTING_FIELD_VALUE:
      const { field, value } = action.payload

      return { ...state, currentItem: { ...state.currentItem, [field]: value.toString() } }

    case GET_SETTING_BY_KEY_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case GET_SETTING_BY_KEY_SUCCESS:
      const setting = action.payload

      return {
        ...state,
        currentItem: {
          key: setting.key,
          value: setting.value
        }
      }

    case GET_SETTING_BY_KEY_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_SETTING_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_SETTING_SUCCESS:
      return { ...state, success: true }

    case EDIT_SETTING_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case DELETE_SETTING_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case DELETE_SETTING_SUCCESS:
      return { ...state, success: true }

    case DELETE_SETTING_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CLEAR_SETTINGS_STATE:
      return initialState

    case CLEAR_SETTINGS_ERROR:
      return { ...state, error: null }

    case CLEAR_SETTINGS_SUCCESS:
      return { ...state, success: false }

    default:
      return state
  }
}

export default settingsReducer
