export const GET_RESTAURANTS_PENDING = 'GET_RESTAURANTS_PENDING'
export const GET_RESTAURANTS_SUCCESS = 'GET_RESTAURANTS_SUCCESS'
export const GET_RESTAURANTS_FAILED = 'GET_RESTAURANTS_FAILED'

export const GET_RESTAURANT_BY_ID_PENDING = 'GET_RESTAURANT_BY_ID_PENDING'
export const GET_RESTAURANT_BY_ID_SUCCESS = 'GET_RESTAURANT_BY_ID_SUCCESS'
export const GET_RESTAURANT_BY_ID_FAILED = 'GET_RESTAURANT_BY_ID_FAILED'

export const CREATE_RESTAURANT_PENDING = 'CREATE_RESTAURANT_PENDING'
export const CREATE_RESTAURANT_SUCCESS = 'CREATE_RESTAURANT_SUCCESS'
export const CREATE_RESTAURANT_FAILED = 'CREATE_RESTAURANT_FAILED'

export const EDIT_RESTAURANT_PENDING = 'EDIT_RESTAURANT_PENDING'
export const EDIT_RESTAURANT_SUCCESS = 'EDIT_RESTAURANT_SUCCESS'
export const EDIT_RESTAURANT_FAILED = 'EDIT_RESTAURANT_FAILED'

export const COPY_RESTAURANT_DISHES_PENDING = 'COPY_RESTAURANT_DISHES_PENDING'
export const COPY_RESTAURANT_DISHES_SUCCESS = 'COPY_RESTAURANT_DISHES_SUCCESS'
export const COPY_RESTAURANT_DISHES_FAILED = 'COPY_RESTAURANT_DISHES_FAILED'

export const CHANGE_RESTAURANT_FIELD_VALUE = 'CHANGE_RESTAURANT_FIELD_VALUE'

export const CLEAR_RESTAURANTS_STATE = 'CLEAR_RESTAURANTS_STATE'
export const CLEAR_RESTAURANTS_ERROR = 'CLEAR_RESTAURANTS_ERROR'
export const CLEAR_RESTAURANTS_SUCCESS = 'CLEAR_RESTAURANTS_SUCCESS'

export const COMMA_WITH_SPACES = /,\s+/g
