import {
  CHANGE_STOCK_FIELD_VALUE,
  CLEAR_STOCKS_ERROR,
  CLEAR_STOCKS_STATE,
  CLEAR_STOCKS_SUCCESS,
  CREATE_STOCK_FAILED,
  CREATE_STOCK_PENDING,
  CREATE_STOCK_SUCCESS,
  EDIT_STOCK_FAILED,
  EDIT_STOCK_PENDING,
  EDIT_STOCK_SUCCESS,
  GET_STOCKS_FAILED,
  GET_STOCKS_PENDING,
  GET_STOCKS_SUCCESS,
  GET_STOCK_BY_ID_FAILED,
  GET_STOCK_BY_ID_PENDING,
  GET_STOCK_BY_ID_SUCCESS
} from '../../constants/stocks'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  currentItem: {
    title: '',
    photoId: null,
    description: '',
    promoType: 'restaurant',
    dishes: [],
    saleType: 'percent',
    sale: 0,
    dateStart: new Date(),
    dateEnd: new Date(),
    categoryId: null
  },
  error: null,
  loading: 'idle',
  success: false
}

const stocksReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_STOCKS_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_STOCKS_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize
      }

    case GET_STOCKS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CREATE_STOCK_PENDING:
      return { ...state, loading: 'pending', error: null }

    case CREATE_STOCK_SUCCESS:
      return { ...state, loading: 'idle', success: true }

    case CREATE_STOCK_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CHANGE_STOCK_FIELD_VALUE:
      const { field, value } = action.payload

      const newState = { ...state, currentItem: { ...state.currentItem, [field]: value } }

      if (field !== 'dateStart' && field !== 'dateEnd') {
        return newState
      }

      const dateStart = newState.currentItem.dateStart
      const dateEnd = newState.currentItem.dateEnd

      if (dateStart > dateEnd) {
        ;[newState.currentItem.dateStart, newState.currentItem.dateEnd] = [dateEnd, dateStart]
      }

      return newState

    case GET_STOCK_BY_ID_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case GET_STOCK_BY_ID_SUCCESS:
      const stock = action.payload

      return {
        ...state,
        currentItem: { ...stock, dateStart: new Date(stock.dateStart), dateEnd: new Date(stock.dateEnd) }
      }

    case GET_STOCK_BY_ID_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_STOCK_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_STOCK_SUCCESS:
      return { ...state, success: true }

    case EDIT_STOCK_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CLEAR_STOCKS_STATE:
      return initialState

    case CLEAR_STOCKS_ERROR:
      return { ...state, error: null }

    case CLEAR_STOCKS_SUCCESS:
      return { ...state, success: false }

    default:
      return state
  }
}

export default stocksReducer
