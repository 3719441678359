export const GET_RESTAURANT_POSITIONS_PENDING = 'GET_RESTAURANT_POSITIONS_PENDING'
export const GET_RESTAURANT_POSITIONS_SUCCESS = 'GET_RESTAURANT_POSITIONS_SUCCESS'
export const GET_RESTAURANT_POSITIONS_FAILED = 'GET_RESTAURANT_POSITIONS_FAILED'

export const GET_RESTAURANT_POSITION_BY_ID_PENDING = 'GET_RESTAURANT_POSITION_BY_ID_PENDING'
export const GET_RESTAURANT_POSITION_BY_ID_SUCCESS = 'GET_RESTAURANT_POSITION_BY_ID_SUCCESS'
export const GET_RESTAURANT_POSITION_BY_ID_FAILED = 'GET_RESTAURANT_POSITION_BY_ID_FAILED'

export const CREATE_RESTAURANT_POSITION_PENDING = 'CREATE_RESTAURANT_POSITION_PENDING'
export const CREATE_RESTAURANT_POSITION_SUCCESS = 'CREATE_RESTAURANT_POSITION_SUCCESS'
export const CREATE_RESTAURANT_POSITION_FAILED = 'CREATE_RESTAURANT_POSITION_FAILED'

export const UPDATE_RESTAURANT_POSITION_PENDING = 'UPDATE_RESTAURANT_POSITION_PENDING'
export const UPDATE_RESTAURANT_POSITION_SUCCESS = 'UPDATE_RESTAURANT_POSITION_SUCCESS'
export const UPDATE_RESTAURANT_POSITION_FAILED = 'UPDATE_RESTAURANT_POSITION_FAILED'

export const DELETE_RESTAURANT_POSITION_PENDING = 'DELETE_RESTAURANT_POSITION_PENDING'
export const DELETE_RESTAURANT_POSITION_SUCCESS = 'DELETE_RESTAURANT_POSITION_SUCCESS'
export const DELETE_RESTAURANT_POSITION_FAILED = 'DELETE_RESTAURANT_POSITION_FAILED'

export const CHANGE_RESTAURANT_POSITION_FIELD_VALUE = 'CHANGE_RESTAURANT_POSITION_FIELD_VALUE'

export const CLEAR_RESTAURANT_POSITIONS_STATE = 'CLEAR_RESTAURANT_POSITIONS_STATE'
export const CLEAR_RESTAURANT_POSITIONS_ERROR = 'CLEAR_RESTAURANT_POSITIONS_ERROR'
export const CLEAR_RESTAURANT_POSITIONS_SUCCESS = 'CLEAR_RESTAURANT_POSITIONS_SUCCESS'
