import {
  CHANGE_BONUS_FIELD_VALUE,
  CLEAR_BONUSES_ERROR,
  CLEAR_BONUSES_STATE,
  CLEAR_BONUSES_SUCCESS,
  CREATE_BONUS_FAILED,
  CREATE_BONUS_PENDING,
  CREATE_BONUS_SUCCESS,
  DELETE_BONUS_FAILED,
  DELETE_BONUS_PENDING,
  DELETE_BONUS_SUCCESS,
  EDIT_BONUS_FAILED,
  EDIT_BONUS_PENDING,
  EDIT_BONUS_SUCCESS,
  GET_BONUS_BY_ID_FAILED,
  GET_BONUS_BY_ID_PENDING,
  GET_BONUS_BY_ID_SUCCESS,
  GET_BONUSES_FAILED,
  GET_BONUSES_PENDING,
  GET_BONUSES_SUCCESS
} from '../../constants/bonuses'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  currentItem: {
    name: '',
    description: '',
    searchTags: [],
    price: '',
    imageId: null,
    cityId: null,
    isActive: true
  },
  error: null,
  loading: 'idle',
  success: false
}

const bonusesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_BONUSES_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_BONUSES_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize
      }

    case GET_BONUSES_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CREATE_BONUS_PENDING:
      return { ...state, loading: 'pending', error: null }

    case CREATE_BONUS_SUCCESS:
      return { ...state, loading: 'idle', success: true }

    case CREATE_BONUS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CHANGE_BONUS_FIELD_VALUE:
      const { field, value } = action.payload

      return { ...state, currentItem: { ...state.currentItem, [field]: value } }

    case GET_BONUS_BY_ID_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case GET_BONUS_BY_ID_SUCCESS:
      const bonus = action.payload

      return {
        ...state,
        currentItem: {
          name: bonus.name,
          description: bonus.description,
          searchTags: bonus.searchTags,
          price: bonus.price,
          imageId: bonus.imageId,
          cityId: bonus.cityId,
          isActive: bonus.isActive
        }
      }

    case GET_BONUS_BY_ID_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_BONUS_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_BONUS_SUCCESS:
      return { ...state, success: true }

    case EDIT_BONUS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case DELETE_BONUS_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case DELETE_BONUS_SUCCESS:
      return { ...state, success: true }

    case DELETE_BONUS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CLEAR_BONUSES_STATE:
      return initialState

    case CLEAR_BONUSES_ERROR:
      return { ...state, error: null }

    case CLEAR_BONUSES_SUCCESS:
      return { ...state, success: false }

    default:
      return state
  }
}

export default bonusesReducer
