import {
  EDIT_ORDER_PENDING,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILED,
  CLEAR_ORDERS_ERROR,
  CLEAR_ORDERS_SUCCESS,
  SET_WAITING_ORDERS_COUNT,
  GET_ORDERS_PENDING,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILED
} from '../../constants/orders'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  error: null,
  loading: 'idle',
  success: false,
  succededFor: null,
  waitingOrdersCount: 0
}

const ordersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_ORDERS_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_ORDERS_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize,
        waitingOrdersCount: items.filter(e => e.status === 'wait').length
      }

    case GET_ORDERS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_ORDER_PENDING:
      return { ...state, loading: 'idle', error: action.payload }

    case EDIT_ORDER_SUCCESS:
      return { ...state, success: true, succededFor: action.payload }

    case EDIT_ORDER_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case SET_WAITING_ORDERS_COUNT:
      return { ...state, waitingOrdersCount: action.payload }

    case CLEAR_ORDERS_ERROR:
      return { ...state, error: null }

    case CLEAR_ORDERS_SUCCESS:
      return { ...state, success: false, succededFor: null }

    default:
      return state
  }
}

export default ordersReducer
