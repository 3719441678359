import {
  GET_BONUS_PAYS_SUCCESS,
  GET_BONUS_PAYS_PENDING,
  GET_BONUS_PAYS_FAILED
} from '../../constants/bonus-pays'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  loading: 'idle'
}

const bonusPaysReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_BONUS_PAYS_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_BONUS_PAYS_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize
      }

    case GET_BONUS_PAYS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    default:
      return state
  }
}

export default bonusPaysReducer
