export const GET_BONUS_COMPENSATIONS_PENDING = 'GET_BONUS_COMPENSATIONS_PENDING'
export const GET_BONUS_COMPENSATIONS_SUCCESS = 'GET_BONUS_COMPENSATIONS_SUCCESS'
export const GET_BONUS_COMPENSATIONS_FAILED = 'GET_BONUS_COMPENSATIONS_FAILED'

export const CREATE_BONUS_COMPENSATION_PENDING = 'CREATE_BONUS_COMPENSATION_PENDING'
export const CREATE_BONUS_COMPENSATION_SUCCESS = 'CREATE_BONUS_COMPENSATION_SUCCESS'
export const CREATE_BONUS_COMPENSATION_FAILED = 'CREATE_BONUS_COMPENSATION_FAILED'

export const CHANGE_BONUS_COMPENSATION_FIELD_VALUE = 'CHANGE_BONUS_COMPENSATION_FIELD_VALUE'

export const CLEAR_BONUS_COMPENSATIONS_STATE = 'CLEAR_BONUS_COMPENSATIONS_STATE'
export const CLEAR_BONUS_COMPENSATIONS_ERROR = 'CLEAR_BONUS_COMPENSATIONS_ERROR'
export const CLEAR_BONUS_COMPENSATIONS_SUCCESS = 'CLEAR_BONUS_COMPENSATIONS_SUCCESS'
