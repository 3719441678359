import {
  GET_BONUS_COMPENSATIONS_SUCCESS,
  GET_BONUS_COMPENSATIONS_PENDING,
  GET_BONUS_COMPENSATIONS_FAILED,
  CREATE_BONUS_COMPENSATION_PENDING,
  CREATE_BONUS_COMPENSATION_SUCCESS,
  CREATE_BONUS_COMPENSATION_FAILED,
  CHANGE_BONUS_COMPENSATION_FIELD_VALUE,
  CLEAR_BONUS_COMPENSATIONS_STATE,
  CLEAR_BONUS_COMPENSATIONS_ERROR,
  CLEAR_BONUS_COMPENSATIONS_SUCCESS
} from '../../constants/bonus-compensations'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  currentItem: {
    sum: ''
  },
  remainder: 0,
  loading: 'idle',
  error: null,
  success: false
}

const bonusCompensationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_BONUS_COMPENSATIONS_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_BONUS_COMPENSATIONS_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize, remainder } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize,
        remainder
      }

    case GET_BONUS_COMPENSATIONS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CREATE_BONUS_COMPENSATION_PENDING:
      return { ...state, loading: 'pending', error: null }

    case CREATE_BONUS_COMPENSATION_SUCCESS:
      return { ...state, loading: 'idle', success: true }

    case CREATE_BONUS_COMPENSATION_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    case CHANGE_BONUS_COMPENSATION_FIELD_VALUE:
      const { field, value } = action.payload

      return { ...state, currentItem: { ...state.currentItem, [field]: value } }

    case CLEAR_BONUS_COMPENSATIONS_STATE:
      return initialState

    case CLEAR_BONUS_COMPENSATIONS_ERROR:
      return { ...state, error: null }

    case CLEAR_BONUS_COMPENSATIONS_SUCCESS:
      return { ...state, success: false }

    default:
      return state
  }
}

export default bonusCompensationsReducer
