export const GET_ORDERS_PENDING = 'GET_ORDERS_PENDING'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED'

export const EDIT_ORDER_PENDING = 'EDIT_ORDER_PENDING'
export const EDIT_ORDER_SUCCESS = 'EDIT_ORDER_SUCCESS'
export const EDIT_ORDER_FAILED = 'EDIT_ORDER_FAILED'

export const SET_WAITING_ORDERS_COUNT = 'SET_WAITING_ORDERS_COUNT'

export const CLEAR_ORDERS_SUCCESS = 'CLEAR_ORDERS_SUCCESS'
export const CLEAR_ORDERS_ERROR = 'CLEAR_ORDERS_ERROR'
