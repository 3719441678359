import {
  GET_WRITE_OFFS_PENDING,
  GET_WRITE_OFFS_SUCCESS,
  GET_WRITE_OFFS_FAILED
} from '../../constants/write-offs'

const initialState = {
  page: 1,
  totalPages: 1,
  totalItems: 0,
  pageSize: 10,
  items: [],
  error: null,
  loading: 'idle'
}

const writeOffsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_WRITE_OFFS_PENDING:
      return { ...state, loading: 'pending', error: null }

    case GET_WRITE_OFFS_SUCCESS:
      const { items, page, totalPages, totalItems, pageSize } = action.payload

      return {
        ...state,
        loading: 'idle',
        items,
        page,
        totalPages,
        totalItems,
        pageSize
      }

    case GET_WRITE_OFFS_FAILED:
      return { ...state, loading: 'idle', error: action.payload }

    default:
      return state
  }
}

export default writeOffsReducer
