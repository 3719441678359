export const GET_USERS_PENDING = 'GET_USERS_PENDING'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILED = 'GET_USERS_FAILED'

export const GET_USER_BY_ID_PENDING = 'GET_USER_BY_ID_PENDING'
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS'
export const GET_USER_BY_ID_FAILED = 'GET_USER_BY_ID_FAILED'

export const CREATE_USER_PENDING = 'CREATE_USER_PENDING'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED'
export const CLEAR_CREATE_USER_STATE = 'CLEAR_CREATE_USER_STATE'

export const EDIT_USER_PENDING = 'EDIT_USER_PENDING'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_FAILED = 'EDIT_USER_FAILED'
export const CLEAR_EDIT_USER_STATE = 'CLEAR_EDIT_USER_STATE'

export const DELETE_USER_PENDING = 'DELETE_USER_PENDING'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'
export const CLEAR_DELETE_USER_STATE = 'CLEAR_DELETE_USER_STATE'

export const CHANGE_USER_FIELD_VALUE = 'CHANGE_USER_FIELD_VALUE'

export const CLEAR_USER_STATE = 'CLEAR_USER_STATE'
