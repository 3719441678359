export const GET_COURIER_PRICES_PENDING = 'GET_COURIER_PRICES_PENDING'
export const GET_COURIER_PRICES_SUCCESS = 'GET_COURIER_PRICES_SUCCESS'
export const GET_COURIER_PRICES_FAILED = 'GET_COURIER_PRICES_FAILED'

export const GET_COURIER_PRICE_BY_ID_PENDING = 'GET_COURIER_PRICE_BY_ID_PENDING'
export const GET_COURIER_PRICE_BY_ID_SUCCESS = 'GET_COURIER_PRICE_BY_ID_SUCCESS'
export const GET_COURIER_PRICE_BY_ID_FAILED = 'GET_COURIER_PRICE_BY_ID_FAILED'

export const CREATE_COURIER_PRICE_PENDING = 'CREATE_COURIER_PRICE_PENDING'
export const CREATE_COURIER_PRICE_SUCCESS = 'CREATE_COURIER_PRICE_SUCCESS'
export const CREATE_COURIER_PRICE_FAILED = 'CREATE_COURIER_PRICE_FAILED'
export const CLEAR_CREATE_COURIER_PRICE_STATE = 'CLEAR_CREATE_COURIER_PRICE_STATE'

export const EDIT_COURIER_PRICE_PENDING = 'EDIT_COURIER_PRICE_PENDING'
export const EDIT_COURIER_PRICE_SUCCESS = 'EDIT_COURIER_PRICE_SUCCESS'
export const EDIT_COURIER_PRICE_FAILED = 'EDIT_COURIER_PRICE_FAILED'
export const CLEAR_EDIT_COURIER_PRICE_STATE = 'CLEAR_EDIT_COURIER_PRICE_STATE'

export const CHANGE_COURIER_PRICE_FIELD_VALUE = 'CHANGE_COURIER_PRICE_FIELD_VALUE'

export const CLEAR_COURIER_PRICE_STATE = 'CLEAR_COURIER_PRICE_STATE'
